import styled from 'styled-components';

interface IProps {
  fontSize?: number
}

const Subtitle = styled.p`
  font-family: 'Roboto', serif;
  text-align: center;
  margin: 4px 0;
  color: #4E4E4E;
  font-size:${(props: IProps) => props.fontSize ? `${props.fontSize}px` : '18px'}
`;


export default Subtitle;
