import {Button as MuiButton, Theme, withStyles} from "@material-ui/core";


const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(MuiButton);

export default Button;
