import React, {useEffect, useState} from 'react';

import AuthContainerForm from '../../components/AuthContainerForm';


import {useFormik} from 'formik';
import {useMutation} from '@apollo/client';
import {LinearProgress, TextField} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import REQUEST_PASSWORD_CHANGE_MUTATION from "../../../../../graphql/auth/requestPasswordChange.mutation";
import Title from "../../../../../shared/components/styled/Title";
import Subtitle from "../../../../../shared/components/styled/Subtitle";
import Form from "../../../../../shared/components/styled/Form";
import {getApolloError} from "../../../../../shared/utils/apolloError";

import styled from 'styled-components';
import ErrorMessage from "../../../../../shared/components/styled/ErrorMessage";
import Button from "../../../../../shared/components/MuiButton";

const Container = styled.div`
width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;`;

const ForgotPassword = () => {
  const history = useHistory();
  const [emailSent, changeEmailSent] = useState(false);
  const [requestPasswordChange, {loading, error, data}] = useMutation(REQUEST_PASSWORD_CHANGE_MUTATION);


  const onSubmit = (values: { email: string }) => {
    requestPasswordChange({variables: {input: {username: values.email}}});
  };

  const validate = (values: { email: string }) => {
    let errors: any = {};

    if (!values.email) {
      errors.email = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validate,
    onSubmit,
    validateOnChange: true,

  });

  const formHasChanged = (value: string): boolean => {
    // @ts-ignore
    return formik.touched[value] && !!formik.errors[value];
  };

  useEffect(() => {
    if (data) {
      changeEmailSent(true);
    }
  }, [data]);


  return (
    <Container>
      {
        emailSent ?
          <AuthContainerForm>
            <Title>Reset your password?</Title>
            <Subtitle>We have sent a reset password email to {formik.values.email}</Subtitle>
            <Subtitle>Please click the reset password link to set your new password.</Subtitle>
          </AuthContainerForm>
          :
          <AuthContainerForm>
            {
              loading &&
              <LinearProgress/>
            }
            {error &&
            <Alert severity="error">{getApolloError(error)}</Alert>
            }
            <Title>Forgot your password?</Title>
            <Subtitle>Don't worry. Just tell us the email address you registered with.</Subtitle>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                className='input'
                type='email'
                id={'email'}
                name={'email'}
                label={'Email'}
                error={formHasChanged('email')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {
                formik.errors['email'] &&
                <ErrorMessage>{formik.errors['email']}</ErrorMessage>
              }
              <Button type='submit' disabled={!formik.isValid || loading} style={{marginTop: 16}}>SUBMIT</Button>
            </Form>
          </AuthContainerForm>
      }

    </Container>
  )
};

export default ForgotPassword;
