import React, {useEffect, useState} from 'react';
import AuthContainerForm from '../../components/AuthContainerForm';
import {useFormik} from 'formik';
import {LinearProgress, TextField} from '@material-ui/core';
import {useMutation} from '@apollo/client';
import {useHistory, useParams} from 'react-router-dom';
import {Alert} from '@material-ui/lab';

import Title from "../../../../../shared/components/styled/Title";
import styled from 'styled-components';
import AuthFooterClicky from "../../../../../shared/components/styled/AuthFooterClicky";
import CHANGE_PASSWORD_MUTATION from "../../../../../graphql/auth/changePassword.mutation";
import {getApolloError} from "../../../../../shared/utils/apolloError";
import Subtitle from "../../../../../shared/components/styled/Subtitle";
import Form from "../../../../../shared/components/styled/Form";
import Button from "../../../../../shared/components/MuiButton";

const Container = styled.div`
width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;`;

const ChangePassword = () => {

  const history = useHistory();
  const params: any = useParams();
  const [resetToken, setresetToken] = useState(null);
  const [passwordUpdated, changePasswordUpdated] = useState(false);
  const [changePassword, {loading, error, data}] = useMutation(CHANGE_PASSWORD_MUTATION);


  const onSubmit = (values: { password: string, repeatPassword: string }) => {
    changePassword({
      variables: {
        password: values.password,
        token: resetToken,
      }
    });
  };

  const validate = (values: { password: string, repeatPassword: string }) => {
    let errors: any = {};
    if (!values.password) {
      errors.passowrd = 'required';
    }
    if (!values.repeatPassword) {
      errors.repeatPassword = 'required';
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = 'Passwords should match';
      errors.passowrd = 'Passwords should match';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    validate,
    onSubmit
  });

  const formHasChanged = (value:string): boolean => {
    // @ts-ignore
    return !!formik.touched[value] && !!formik.errors[value];
  };

  useEffect(() => {
    if (params) {
      if (params.token) {
        setresetToken(params.token);
      }
    }
  }, [params]);

  useEffect(() => {
    if (data) {
      changePasswordUpdated(true);
    }
  }, [data]);

  return (
    <Container>
      {
        passwordUpdated ?
          <AuthContainerForm>
            <Title>Password updated</Title>
            <Subtitle>You can use your new password to enter your account.</Subtitle>
          </AuthContainerForm>
          :
          <AuthContainerForm>
            {
              loading &&
              <LinearProgress/>
            }
            {error &&
            <Alert severity="error">{getApolloError(error)}</Alert>
            }
            <Title>Change your password</Title>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                className='input'
                type='password'
                id={'password'}
                name={'password'}
                label={'Password'}
                error={formHasChanged('password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField className='input'
                         type='password'
                         id={'repeatPassword'}
                         name={'repeatPassword'}
                         label={'Repeat Password'}
                         error={formHasChanged('repeatPassword')}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
              />
              <Button type='submit' style={{marginTop: 16}} disabled={!formik.isValid || loading}>CHANGE
                PASSWORD</Button>
            </Form>
            <AuthFooterClicky onClick={() => history.push('/auth/forgot-password')} style={{marginTop: 16}}>Token
              expired or
              want to resend new email?</AuthFooterClicky>
          </AuthContainerForm>
      }

    </Container>
  )
};

export default ChangePassword;
