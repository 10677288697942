import React from 'react'
import {Switch, useRouteMatch, Route} from 'react-router-dom';
import ChangePassword from './pages/ChangePassword';
import VerifyEmail from './pages/VerifyEmail';

import {Container, styled} from "@material-ui/core";
import {AUTH_ROUTES} from "../../../shared/constants/routes";
import ForgotPassword from "./pages/ForgotPassword";

const AuthContainer = styled(Container)({
  paddingTop: 18,
  paddingBottom: 18,
});


const AuthRouter = () => {

  let {path} = useRouteMatch();


  return (
    <AuthContainer>
      <Switch>
        <Route exact path={`${path}${AUTH_ROUTES.CHANGE_PASSWORD}/:token`} component={ChangePassword}/>
        <Route exact path={`${path}${AUTH_ROUTES.VERIFY_EMAIL}/:token`} component={VerifyEmail}/>
        <Route path={`${path}${AUTH_ROUTES.FORGOT_PASSWORD}`} component={ForgotPassword}/>
      </Switch>
    </AuthContainer>
  )
};

export default AuthRouter;
