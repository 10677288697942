export const ROOT_ROUTES = {
  AUTH: 'auth',
};

export const AUTH_ROUTES = {
  BASE: '/auth',
  CHANGE_PASSWORD: '/change-password',
  VERIFY_EMAIL: '/verify-email',
  FORGOT_PASSWORD: '/forgot-password',
};
