const getApolloError = (error: any) => {
  if(!error){
    return null
  }
  if (error.graphQLErrors) {
    if (error.graphQLErrors[0] && error.graphQLErrors[0].details && error.graphQLErrors[0].details.length > 0) {
      return error.graphQLErrors[0].details[0];
    }
  }
  if (error.message) {
    return error.message;
  }
  return 'There was an error creating a new Location';
};


export { getApolloError };
