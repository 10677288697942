import styled from 'styled-components';


const Title = styled.p`
    font-family: 'Roboto', serif;
    text-align: center;
    margin: 4px 0;
    color:#4E4E4E;
    font-weight: bold;
    font-size: 30px;
`;


export default Title;
