import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route
} from 'react-router-dom'
import {AUTH_ROUTES, ROOT_ROUTES} from "../../shared/constants/routes";
import AuthRouter from "./Auth";

const Root = () => {

  return (
    <Router>
      <Route path={`/${ROOT_ROUTES.AUTH}`} component={AuthRouter}/>
      <Route exact path="/">
        <Redirect to={{pathname: `/${ROOT_ROUTES.AUTH}${AUTH_ROUTES.FORGOT_PASSWORD}`}}/>
      </Route>
    </Router>
  )
};
export default Root;
