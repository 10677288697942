import styled from "styled-components";

const AuthContainerForm = styled.div`
  background-color: #E0E0E0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 24px;
  max-width: 40%;
  text-align: center;
`;


export default AuthContainerForm;
