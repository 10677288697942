import React, {useEffect, useState} from 'react';
import AuthContainerForm from '../../components/AuthContainerForm';
import {useParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import VerifyEmailError from './components/VerifyEmailError';
import {LinearProgress} from '@material-ui/core';
import {getApolloError} from "../../../../../shared/utils/apolloError";
import VERIFY_EMAIL from "../../../../../graphql/auth/verifyEmail.mutation";
import Title from "../../../../../shared/components/styled/Title";
import Subtitle from "../../../../../shared/components/styled/Subtitle";
import styled from 'styled-components';

const Container = styled.div`
width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;`;

const VerifyEmail = () => {

  const params = useParams();

  const [verifyToken, setVerifyToken] = useState<string | null>(null);
  const [emailVerified, changeEmailVerified] = useState(false);
  const [verifyEmail, {loading, data: verifyEmailData, error: verifyEmailError}] = useMutation(VERIFY_EMAIL);


  useEffect(() => {
    if (verifyToken) {
      verifyEmail({
        variables: {
          token: verifyToken,
        }
      }).catch(e => console.log(e));
    }
  }, [verifyToken]);


  useEffect(() => {
    //@ts-ignore
    if (params && params.token) {
      //@ts-ignore
      setVerifyToken(params.token);
    }
  }, [params]);

  useEffect(() => {
    if (verifyEmailData) {
      changeEmailVerified(true);
    }
  }, [verifyEmailData]);

  return (
    <Container>
      <AuthContainerForm>
        {
          verifyEmailError ?
            ((getApolloError(verifyEmailError) === 'Email already verified') ?
              <>
                <Title>Your Email was already Verified!</Title>
                <Subtitle>You can login to your account through the mobile app or web app.</Subtitle>
              </>
              :
              <VerifyEmailError error={verifyEmailError}/>)
            :
            <>
              {
                (emailVerified && !loading) ?
                  <>
                    <Title>Email Verified!</Title>
                    <Subtitle>You can login to your account.</Subtitle>
                  </>
                  :
                  <>
                    <Title>Verifying email</Title>
                    <LinearProgress/>

                  </>
              }
            </>
        }
      </AuthContainerForm>
    </Container>
  )
};


export default VerifyEmail;


