import {gql} from '@apollo/client';

const REQUEST_PASSWORD_CHANGE_MUTATION = gql`
    mutation RequestPasswordChange($input: RequestPasswordChangeInput!) {
        RequestPasswordChange(input:$input) {
            message
        }
    }
`;

export default REQUEST_PASSWORD_CHANGE_MUTATION;
