import styled from 'styled-components';


const ErrorMessage = styled.p`
    font-family: 'evolve-regular';
    margin: 4px 0;
    color:#F44336;
    font-size: 12px
`;


export default ErrorMessage;