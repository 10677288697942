import React from 'react';
import {ApolloProvider} from "@apollo/client";
import apolloClient from "../config/apolloClient";
import Root from "./container";
import './App.css'


function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Root/>
    </ApolloProvider>
  );
}

export default App;
