import {gql} from '@apollo/client';

const REQUEST_EMAIL_VERIFICATION = gql`
    mutation requestEmailVerification($input: RequestEmailVerificationInput!) {
        RequestEmailVerification(input:$input) {
            message
        }
    }
`;

export default REQUEST_EMAIL_VERIFICATION;
